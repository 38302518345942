<template>
    <v-app id="inspire">
        <div class="px-4">
            <loading
                :is-show="loadingDialog"
                :is-done="loadingFinished"
                :is-html="isHtml"
                :message="loadingMessage"
                v-on:close-dialog="closeDialog"
            />
            <acquirer-form
                ref="acquirerForm"
                :selected-item="selectedAcquirer"
                :is-show="showFormDialog"
                :branches="branches"
                :deviceTypes="deviceTypes"
                :paymentAgents="paymentAgents"
                :isNotPaymentAgent="isNotPaymentAgent"
                :users="users"
                :merchants="merchants"
                :acquirerList="acquirerList"
                :form-type="acquirerFormType"
                v-on:close-dialog="closeDialog"
                v-on:save-acquirer="saveAcquirer"
            />
            <v-container class="container--fluid grid-list-md text-left" :style="{ fontSize: '15px', color: '#1d6499' }">
                <v-icon :style="{ color: '#1d6499', paddingBottom: '11px', marginRight: '5px' }" left-bottom x-large
                    >mdi-cogs</v-icon
                >
                ACQUIRER <span class="text-h5 font-weight-bold"> ({{ totalAcquirers }}) </span>
            </v-container>
            <v-card elevation="2" outlined shaped tile class="pa-1">
                <div class="d-flex flex-no-wrap justify-space-between">
                    <v-card-title>
                        <v-container grid-list-md text-xs-center>
                            <v-layout row wrap>
                                <v-flex>
                                    <div class="d-flex">
                                        <span class="mt-4 mr-2">Search By:</span>
                                        <v-text-field
                                            class="mr-3"
                                            label="Acquirer Name"
                                            v-model="query.acquirerName"
                                            single-line
                                            outlined
                                            @change="search($event, 'acquirerName')"
                                            data-cy="module-acquirer-name"
                                        ></v-text-field>
                                    </div>
                                </v-flex>
                            </v-layout>
                        </v-container>
                    </v-card-title>
                </div>
                <div class="d-flex flex-no-wrap justify-space-between mr-lg-10">
                    <!-- <v-card-title> -->
                    <v-container grid-list-md text-xs-center>
                        <v-layout wrap class="float-lg-right">
                            <v-flex xs12 sm4 v-if="hasPermission('ROLE_TDM_VIEW_TERMINAL')">
                                <v-btn :block="$vuetify.breakpoint.mdAndDown"  style="min-width: 200px;" class="mt-2" color="primary" @click="resetInputs()" data-cy="button-search">
                                    <v-icon dark left> mdi-history </v-icon> Reset
                                </v-btn>
                            </v-flex>
                            <v-flex xs12 sm4 v-if="hasPermission('ROLE_TDM_VIEW_TERMINAL')">
                                <v-btn
                                    :block="$vuetify.breakpoint.mdAndDown"
                                    style="min-width: 200px"
                                    class="mt-2"
                                    color="primary"
                                    @click="queryData(true)"
                                    data-cy="button-search"
                                >
                                    <v-icon dark left> mdi-magnify</v-icon> Search
                                </v-btn>
                            </v-flex>
                            <v-flex xs12 sm4 v-if="hasPermission('ROLE_TDM_CREATE_TERMINAL')">
                                <v-btn
                                    :block="$vuetify.breakpoint.mdAndDown"
                                    style="min-width: 200px"
                                    class="mt-2"
                                    color="primary"
                                    @click.stop="showAcquirerForm('CREATED')"
                                    data-cy="button-create"
                                >
                                    <v-icon dark left> mdi-plus</v-icon>Create New Acquirer
                                </v-btn>
                            </v-flex>
                        </v-layout>
                    </v-container>
                    <!-- </v-card-title> -->
                </div>
            </v-card>

            <v-data-table
                :footer-props="{'items-per-page-options': [10, 20, 50]}"
                :hide-default-header="true"
                :headers="headers"
                :items="displayAcquirerList"
                :loading="loading"
                :page="page"
                :pageCount="numberOfPages"
                :options.sync="options"
                :server-items-length="totalAcquirers"
                class="elevation-2 mt-4 px-4"
            >
                <template v-slot:header="{ props: { headers } }">
                    <thead>
                        <tr :colspan="headers.length">
                            <th v-for="header in headers" :key="header.text">
                                <span class="d-flex justify-center">{{ header.text }}</span>
                            </th>
                        </tr>
                    </thead>
                </template>
                <template v-slot:body="{ items }">
                    <tbody>
                        <tr :colspan="headers.length" v-for="item in items" :key="item.id">
                            <!-- <td> <span class="d-flex justify-center"> {{ item.country.name }} </span></td> -->
                            <td>
                                <span class="d-flex justify-center"> {{ item.acquirerId }} </span>
                            </td>
                            <td>
                                <span class="d-flex justify-center"> {{ item.acquirerName }} </span>
                            </td>
                            <td>
                                <span class="d-flex justify-center">
                                    <!-- <v-btn fab dark x-small color="primary" class="mr-2" @click.stop="viewTerminal(item)"> -->
                                    <v-btn
                                        fab
                                        dark
                                        x-small
                                        color="primary"
                                        class="mr-2"
                                        @click.stop="showAcquirerForm('VIEW', item)"
                                        v-if="hasPermission('ROLE_TDM_VIEW_TERMINAL')"
                                    >
                                        <v-icon dark medium>mdi-eye</v-icon>
                                    </v-btn>
                                    <v-btn
                                        fab
                                        dark
                                        x-small
                                        color="primary"
                                        @click.stop="showAcquirerForm('UPDATE', item)"
                                        v-if="hasPermission('ROLE_TDM_UPDATE_TERMINAL')"
                                    >
                                        <v-icon dark medium>mdi-pencil</v-icon>
                                    </v-btn>
                                </span>
                            </td>
                        </tr>
                    </tbody>
                </template>
                <template v-slot:no-results>
                    <v-alert :value="true" color="error" icon="warning"> Found no results. </v-alert>
                </template>
            </v-data-table>
        </div>
    </v-app>
</template>

<script>
import API from "@/api/API.js";
import Loading from "@/views/components/Loading.vue";
import acquirerForm from "@/views/components/acquirerForm.vue";
import { mapGetters } from "vuex";

export default {
    name: "AcquirerList",
    components: {
        Loading,
        acquirerForm,
    },
    data() {
        return {
            filterQry: {
                qryName: "",
            },
            headers: [],
            displayAcquirerList: [],
            remainderAcquirerList: [],
            // authorities: [],
            merchants: [],
            branches: [],
            deviceTypes: [],
            paymentAgents: [],
            isNotPaymentAgent: false,
            users: [],
            acquirerList: [],
            loading: true,
            loadingMessage: "",
            loadingDialog: false,
            loadingFinished: false,
            selectedAcquirer: {},
            page: 1,
            totalAcquirers: 0,
            numberOfPages: 0,
            acquirerFormType: "CREATED",
            options: {
                rowsPerPage: 10,
            },
            details: {
                title: "",
                item: {},
            },
            query: {
                acquirerId: "",
                acquirerName: "",
            },
            isShowDetails: false,
            showFormDialog: false,
            isHtml: false,
        };
    },
    watch: {
        options: {
            handler() {
                console.log("1");
                this.queryData();
                this.setData();
            },
        },
        deep: true,
    },
    computed: {
        //get role attributes
        ...mapGetters(["attributes", "user", "authorities"]),
    },
    async mounted() {
        this.setHeaders();
        //this.queryData()
    },
    methods: {
        async setData() {
            this.loading = true
            this.loadingDialog = true
            this.loadingMessage = `Fetching data, please wait.`
            this.loadingFinished = false
            try {
                const listResponse = await API.getAcquirerOptions()
                if (!listResponse || listResponse.error) {
                    //error getting data
                    console.log(`${listResponse.error}`)
                } else {
                    this.acquirerList = listResponse.acquirerList
                }
            } catch (e) {
                console.log(e)
            }
            this.loading = false
            this.loadingDialog = false
            this.loadingFinished = true
        },
        resetInputs() {
            this.query.acquirerId = ''
            this.query.acquirerName = ''
            this.displayAcquirerList = [];
            this.queryData();
            this.setData();
        },
        hasPermission(authority) {
            if (this.authorities.some((x) => x.name === authority)) {
                return true;
            }
            return false;
        },
        async queryData(isSearch = false) {
            this.loading = true;
            if (isSearch) {
                this.options.page = 1;
            }
            const { page, itemsPerPage } = this.options;
            try {
                console.log(this.query);
                const listResponse = await API.getAcquirers(this.query);
                if (!listResponse || listResponse.error) {
                    //error getting data
                    console.log(`${listResponse.error}`);
                } else {
                    //use old code for pagination
                    var filteredList = listResponse.acquirers;
                    console.log("filteredList", filteredList);
                    if (page > 1 && this.remainderAcquirerList.length > 0) {
                        filteredList = this.remainderAcquirerList.concat(filteredList);
                    }
                    this.totalAcquirers = listResponse.totalItems
                    const end = filteredList.length < itemsPerPage ? filteredList.length : itemsPerPage
                    this.displayAcquirerList = filteredList.slice(0, end)
                    this.remainderAcquirerList = filteredList.length < itemsPerPage ? [] : filteredList.slice(end)
                    this.numberOfPages = listResponse.pageCount
                }
                this.loading = false;
            } catch (e) {
                console.log(e);
                this.loading = false;
            }
        },
        setHeaders() {
            var headers = [
                {
                    text: "Acquirer ID",
                    value: "acquirerId",
                },
                {
                    text: "Acquirer Name",
                    value: "acquirerName",
                },
                {
                    text: "Actions",
                    value: "controls",
                    sortable: false,
                },
            ];
            this.headers = headers;
        },
        showAcquirerForm(what, item = {}) {
            this.acquirerFormType = what;
            this.selectedAcquirer = item;
            this.showFormDialog = true;
        },
        closeDialog(type) {
            console.log("type:", type);
            if (type === "details") {
                this.details.title = "";
                this.details.item = {};
                this.isShowDetails = false;
            } else if (type === "CREATED" || type === "UPDATE" || type === "VIEW") {
                this.showFormDialog = false;
            } else if (type === "loading") {
                this.loadingDialog = false;
                this.queryData();
            } else if (type === "loading-error") {
                this.loadingDialog = false;
            }
        },
        async saveAcquirer(value) {
            this.loadingDialog = true;
            this.isHtml = true;
            this.loadingFinished = false;
            if (value) {
                console.log(value);
                if (value.formType === "CREATED") {
                    this.loadingMessage = `Creating new Acquirer<strong><i>${value.acquirerId}</i></strong>`;
                    setTimeout(async () => {
                        const createResponse = await API.createAcquirer(value);
                        console.log("CREATE ACQUIRER",createResponse);
                        this.loadingFinished = true;
                        if (!createResponse || createResponse.error) {
                            this.isHtml = false;
                            this.loadingMessage = createResponse.error;
                        } else {
                            this.loadingMessage = `Successfully created Acquirer <strong><i>${value.acquirerId}</i></strong>`;
                            this.showFormDialog = false;
                            this.$refs.acquirerForm.resetForm();
                        }
                    }, 2000);
                } else if (value.formType === "UPDATE") {
                    this.loadingMessage = `Updating Acquirer <strong><i>${value.acquirerId}</i></strong>`;
                    setTimeout(async () => {
                        const updateResponse = await API.updateAcquirer(value);
                        this.loadingFinished = true;
                        if (!updateResponse || updateResponse.error) {
                            this.isHtml = false;
                            this.loadingMessage = updateResponse.error;
                        } else {
                            this.loadingMessage = `Successfully updated Acquirer <strong><i>${value.acquirerId}</i></strong>`;
                            this.showFormDialog = false;
                            this.$refs.acquirerForm.resetForm();
                        }
                    }, 2000);
                } else {
                    this.loadingMessage = "Invalid command";
                    this.loadingFinished = true;
                }
            } else {
                this.loadingMessage = "Error processing data.";
                this.loadingFinished = true;
            }
        },
        search(event, type) {
            this.query[type] = event;
        },
    },
};
</script>

<style scoped>
.v-data-table {
    max-width: 100%;
}
</style>
