<template>
<v-layout row justify-center>
    <v-dialog v-model="dialog" scrollable persistent max-width="1180px">
        <v-card ref="formCard">
            <v-card-title>
                <span class="headline ma-5" v-if="formType === 'CREATED'">Create New Acquirer</span>
                <span class="headline ma-5" v-else-if="formType === 'UPDATE'">Update Acquirer</span>
                <span class="headline ma-5" v-else-if="formType === 'VIEW'">View Acquirer</span>
            </v-card-title>
            <v-card-text>
                <v-form :disabled="formType === 'VIEW'" ref="formEntry">
                    <v-container grid-list-md>
                        <v-layout row wrap>
                            <v-flex xs12 sm6>
                                <v-row class="align-center" no-gutters>
                                    <v-col class="ml-5">
                                        <span>Acquirer ID <span style="color: red;">*</span></span>
                                    </v-col>
                                    <v-col>
                                        <v-text-field :readonly="formType === 'UPDATE'" :label="formType === 'VIEW' ? '' : 'Acquirer ID'" v-numeric-input v-model="form.acquirerId" required :rules="[rules.requiredField]" data-cy="form-acquirer-id"></v-text-field>
                                    </v-col>              
                                </v-row>
                                <v-row class="align-center" no-gutters>
                                    <v-col class="ml-5">
                                        <span>Acquirer Name <span style="color: red;">*</span></span>
                                    </v-col>
                                    <v-col>
                                        <v-select 
                                            item-text="acquirerCode" 
                                            item-value="acquirerCode" 
                                            :items="acquirerList" 
                                            :label="formType === 'VIEW' ? '' : 'Acquirer Name'" 
                                            v-model="form.acquirerName" 
                                            :rules="[rules.requiredField]" 
                                            data-cy="form-acquirer-name">
                                        </v-select>
                                    </v-col>              
                                </v-row>
                            </v-flex>
                        </v-layout>
                    </v-container>
                </v-form>
            </v-card-text>
            <v-card-actions class="ma-5">
                <small v-if="formType != 'VIEW'">*indicates required field</small>
                <v-spacer></v-spacer>
                <v-btn color="primary" style="min-width: 100px;"  v-on:click="close" data-cy="close-button">Close</v-btn>
                <v-btn color="primary" style="min-width: 100px;"  v-if="formType != 'VIEW'" v-on:click="save" data-cy="save-button">Save</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</v-layout>
</template>

<script>
export default {
    props: {
        isShow: Boolean,
        formType: String,
        loadingDialog: Boolean,
        selectedItem: Object,
        users: Array,
        acquirerList: Array,
    },
    directives: {
        'numeric-input': {
            inserted(el) {
                el.addEventListener('input', (event) => {
                const value = event.target.value;
                event.target.value = value.replace(/\D/g, ''); // Allow only numeric characters
                });
            }
        }
    },
    computed: {
        inputRules() {
            return [
                value => /^[a-zA-Z0-9]*$/.test(value) || 'Only alphanumeric characters are allowed',
            ];
        },
        populatedForm() {
            return {
                acquirerId: this.form.acquirerId,
                acquirerName: this.form.acquirerName,
                formType: this.formType
            }
        },
    },
    watch: {
        isShow: {
            handler(value) {
                this.dialog = value
            }
        },
        loadingDialog: {
            handler(value) {
                this.loading = value
            },
        },
        dialog: {
            handler(value) {
                if (value === true) {
                    this.initializeForm()
                }
            }
        },
        acquirerList: {
            handler(value) {
                this.acquirerList = value
            }
        },
        deep: true,
    },
    data() {
        return {
            dialog: this.isShow ?? false,
            loading: this.loadingDialog ?? false,
            trmDataPresentList: ['1', '0'],
            acquirerList: this.acquirerList ?? [],
            form: {
            },
            // rules 
            rules: {
                requiredField: v => !!v || "Required field.",
                requiredSelection: v => !!v && v.length > 0 || "Required at least one selection"
            },
        }
    },
    mounted() {
        this.initializeForm()
    },
    methods: {
        close() {
            this.dialog = false
            this.$refs.formEntry.reset()
            this.$emit('close-dialog', this.formType)
        },
        save() {
            if (this.$refs.formEntry.validate()) {
                this.$emit('save-acquirer', this.populatedForm)
                this.$refs.formCard.$el.scrollIntoView({
                    behavior: 'smooth'
                })
            }
        },
        resetForm() {
            this.$refs.formEntry.reset()
        },
        getTrmDataPresent: async function (val) {
            this.form.trmDataPresent = val
        },
        initializeForm() {
            this.e1 = 1;
            if (this.formType === 'CREATED') {
                this.form = {
                    acquirerId: undefined,
                    acquirerName: undefined,
                }
            } else if ((this.formType === 'UPDATE' || this.formType === 'VIEW') && this.selectedItem) {
                this.form = {
                    acquirerId: this.selectedItem.acquirerId,
                    acquirerName: this.selectedItem.acquirerName,
                }
            }
        }
    }
}
</script>